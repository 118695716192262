import { observable, action, runInAction } from "mobx";
import storeUtils from "../lib/StoreUtils";
// import queryUtils from '../lib/QueryUtils';

class GlobalStore {
  @observable atreemo = {
    authentication: "",
    expires: "",
  };

  @observable NavigationItems = {
    navigation: {},
    footer: {},
    socialMedia: {},
    consent: {},
  };

  @observable Booking = {
    eatAndDrinkOutletId: [
      {
        outletId: "",
      },
    ],
  };

  @observable modalOpen = false;
  @observable status = "pending"; // "pending" / "done" / "error"

  /**
   * Requests the data from the CraftQL API
   * Updates the stored content
   */
  @action.bound async fetchContent() {
    try {
      const query = `
      {
        consent: globalSets(handle: "consent") {
          ... on consent_GlobalSet {
            consent {
              ... on consent_consentGroup_BlockType {
                tAndCsText
                marketing
                privacyPolicyText
              }
            }
          }
        }
        navigation: globalSets(handle:"navigation") {
          ... on navigation_GlobalSet {
            navigation {
              ... on navigation_item_BlockType {
                id
                heading
                buttonEntry {
                  id
                  uri
                }
                externalLink
                openInNewTab
              }
            }
          }
        }
        footer: globalSets(handle:"footer")  {
          ... on footer_GlobalSet {
            navigation {
              ... on navigation_item_BlockType {
                id
                heading
                buttonEntry {
                  id
                  uri
                }
                externalLink
                openInNewTab
              }
            }
          }
        }
        socialMedia: globalSets(handle:"socialMedia")  {
          ... on socialMedia_GlobalSet {
            socialMedia {
              ... on socialMedia_socialMediaLinks_BlockType {
                id
                facebook
                twitter
                instagram
              }
            }
          }
        }
        
        booking: globalSets(handle: "booking") {
          ... on booking_GlobalSet {
            id
            eatAndDrinkOutletId {
              outletId
            }
          }
        }
      }
      `;

      const content = await storeUtils.fetchPageData(query);
      runInAction(() => {
        this.status = "done";
        this.NavigationItems = {
          navigation: content.navigation[0],
          footer: content.footer[0],
          socialMedia: content.socialMedia[0],
          consent: content.consent[0]
        };
        this.Booking = content.booking[0];
      });
    } catch (error) {
      runInAction(() => {
        this.status = "error";
      });
    }
  }

  @action.bound modalToggle() {
    this.modalOpen = !this.modalOpen;
  }
}

const GLOBALSTORE = new GlobalStore();

export default GLOBALSTORE;
